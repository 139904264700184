<template>
	<div class="what-we-do-section-container" :class="{ 'no-bg': getInTouch }">
		<div :class="[getClasses, { 'sm-header': blockData.smallHeader }]">
			<div>
				<h1
					ref="title"
					:class="blockData.smallHeader ? 'title-small' : 'title'"
				>
					{{ blockData.title }}
				</h1>
				<p class="paragraph" ref="paragraph">
					<sanity-rich-text
						v-if="blockData.text"
						:blocks="blockData.text"
					></sanity-rich-text>
				</p>

				<Link
					:link="blockData.link"
					v-if="
						blockData.link &&
						(blockData.link.pages || blockData.link.externalLink)
					"
				>
					<button class="what-we-do-btn">
						<h3>
							{{
								blockData.buttonText ?? "LEARN ABOUT WHAT WE DO"
							}}
						</h3>
					</button>
				</Link>

				<!-- <router-link v-if="getInTouch" class="git" to="/work-together"
					>Get in Touch</router-link
				> -->
			</div>
			<div class="picture-container">
				<img
					:src="getImage(blockData.image)"
					alt=""
					class="what-we-do-pic"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { SplitText } from "gsap/dist/SplitText";
import sanity from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import Link from "../../components/new/Link.vue";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);
const imageBuilder = imageUrlBuilder(sanity);
export default {
	name: "TwoColumnContent",
	props: {
		blockData: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		Link
	},
	created() {
		setTimeout(function () {
			ScrollTrigger.refresh();
		}, 50);
	},
	mounted() {
		ScrollTrigger.create({
			trigger: ".paragraph",
			toggleActions: "play none none none",
			onEnter: () => this.timeline(),

			onLeaveBack: (self) => self.disable()
		});

		ScrollTrigger.create({
			trigger: ".paragraph",
			toggleActions: "play none none none",
			onEnter: () => this.timelinePara(),

			onLeaveBack: (self) => self.disable()
		});
	},
	computed: {
		getClasses() {
			const align = this.blockData.align;
			if (align === "imageLeft") {
				return "flex-image-left";
			}
			if (align === "standard") {
				return "flex";
			}
			if (align === "imageStandardEdge") {
				return "flex-edge-standard";
			}
			if (align === "imageLeftEdge") {
				return "flex-edge-left";
			}
			return "flex";
		},
		getInTouch() {
			return (
				this.blockData.align === "imageStandardEdge" ||
				this.blockData.align === "imageLeftEdge"
			);
		}
	},
	methods: {
		timeline() {
			if (!this.$refs?.title) return false;
			this.$refs.title.style.opacity = "1";
			let tl = gsap.timeline(),
				mySplitText = new SplitText(this.$refs.title, {
					type: "lines"
				}),
				lines = mySplitText.lines;

			gsap.set(this.$refs.title, { perspective: 400 });

			tl.from(
				lines,
				{
					y: 40,
					opacity: 0,
					duration: 0.6,
					stagger: 0.1,
					rotationX: 80,
					transformOrigin: "20% 0 0"
				},
				"+=0"
			);
		},
		timelinePara() {
			if (this.$refs?.title) this.$refs.title.style.opacity = "1";
		},
		getImage(image) {
			return imageBuilder.image(image);
		}
	}
};
</script>

<style scoped>
.what-we-do-section-container {
	padding-top: 8vw;
	padding-bottom: 15vw;
	width: 100vw;
	background: #f7f5f2;

	margin-top: 0;
}

.no-bg {
	background: transparent;
}

.git {
	position: relative;
	margin-top: 2.5vw;
	text-align: left;
	-webkit-transition: 1s;
	transition: 1s;
	font-size: 1.3vw;
	float: left;
	opacity: 1;
	font-weight: 700;
	cursor: pointer;
	font-family: DM sans;
	color: #544a3f;
	-webkit-text-decoration-color: #d4c09e;
	text-decoration-color: #d4c09e;
	text-decoration: underline;
}
.title {
	position: relative;
	text-align: left;
	/*margin-left: 11vw;*/
	line-height: 5.8vw;
	font-size: 6.25vw;
	width: 35vw;
	/*margin-top: 2vw;*/
	opacity: 0;
}
.title-small {
	position: relative;
	text-align: left;
	width: 88vw;
	display: inline-block;
	opacity: 0;
	margin-bottom: 0.67em;
}

@media (min-aspect-ratio: 200/200) {
	.title-small {
		position: relative;
		text-align: left;
		font-size: 3vw;
		width: 35vw;
		display: inline-block;
		opacity: 0;
		margin-bottom: 0.67em;
	}
}

.sm-header.flex-image-left .picture-container {
	width: 45vw;
}

@media (max-aspect-ratio: 200/200) {
	.sm-header.flex-image-left .picture-container {
		width: 88vw;
	}
}

.flex {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

@media (max-aspect-ratio: 200/200) {
	.flex {
		display: contents;
	}
}

.flex-edge-standard {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.flex-edge-standard {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.flex-edge-standard .title {
	width: 43vw;
}

@media (max-aspect-ratio: 200/200) {
	.flex-edge-standard {
		display: contents;
	}

	.flex-edge-standard a {
		font-size: 4vw;
		left: 22px;
	}
}

.flex-edge-standard .title {
	width: 43vw;
}

.flex-edge-standard div:first-child {
	margin-right: 10vw;
}

.flex-image-left {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-evenly;
	align-items: center;
}

@media (max-aspect-ratio: 200/200) {
	.flex-image-left {
		display: contents;
	}
	.flex-edge-standard .title {
		width: 67vw;
	}
}

.flex-edge-left {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;
}

.flex-edge-left div:first-child {
	margin-left: 10vw;
}

.flex-edge-left .picture-container {
	height: 40vw;
}

.flex-edge-left .picture-containr img {
	height: 40vw;
	object-fit: cover;
}

.flex-edge-standard .picture-container {
	height: 40vw;
	background-color: transparent;
}

.flex-edge-standard .picture-container img {
	height: 40vw;
	object-fit: cover;
}

.picture-container {
	position: relative;
	height: 37vw;
	width: 37vw;
	background: rgb(255, 255, 255);
	overflow: hidden;
}
.what-we-do-pic {
	width: 100%;
	position: relative;
}
.what-we-do-section-container img {
	height: 100%;
	object-fit: cover;
}
.paragraph {
	position: relative;
	margin-top: -2.5vw;
	text-align: left;
	/*margin-left: 11vw;*/
	transition: 1s;
	font-size: 1.3vw;
	width: 35vw;
	opacity: 1;
	font-family: DM sans;
}

.what-we-do-btn {
	position: relative;
	left: 0;
	margin-top: 50px;
	width: 18vw;
	height: 4.5vw;
	display: block;

	background: none;
	border: 0.2vw solid #d4c09e;
	cursor: pointer;
}

.what-we-do-btn h3 {
	font-size: 0.9vw;
	color: #544a3f;
	padding: 0.45vw;
}

@media (max-aspect-ratio: 200/200) {
	.flex-edge-standard .picture-container {
		height: 85vw;
	}

	.flex-edge-standard .picture-container img {
		height: 85vw;
	}

	.title {
		margin-left: 6vw;
		line-height: 10vw;

		font-size: 11.25vw;
		width: 90vw;
		margin-top: 2vw;
	}
	.paragraph {
		text-align: left;
		font-size: 3.8vw;
		width: 88vw;
		margin-left: 6vw;
	}
	.picture-container {
		margin-left: 6vw;
		left: 0;
		margin-top: 10vw;
		width: 88vw;
		height: 80vw;
	}
	.what-we-do-btn {
		margin-top: 3vw;
		margin-left: 6vw;
		width: 50vw;
		height: 15vw;

		border: 0.5vw solid #d4c09e;
	}

	.what-we-do-btn h3 {
		font-size: 2.5vw;
		color: #544a3f;
		padding: 0.45vw;
	}
	.what-we-do-section-container {
		padding-bottom: 25vw;
	}
}
</style>
