<template>
	<div class="talkseries what-we-do-section-container">
		<div class="flex sm-header">
			<div>
				<h1 ref="title" class="title-small">
					{{ blockData.title }}
				</h1>
				<p class="paragraph" ref="paragraph">
					<sanity-rich-text
						v-if="blockData.text"
						:blocks="blockData.text"
					></sanity-rich-text>
				</p>

				<Link
					:link="blockData.link"
					v-if="
						blockData.link &&
						(blockData.link.pages || blockData.link.externalLink)
					"
				>
					<button class="what-we-do-btn">
						<h3>
							{{
								blockData.buttonText ?? "LEARN ABOUT WHAT WE DO"
							}}
						</h3>
					</button>
				</Link>
			</div>

			<div class="picture-container">
				<img
					:src="getImage(blockData.image)"
					alt=""
					class="what-we-do-pic"
				/>
				<div
					class="carousel-container"
					v-if="blockData.carousel && blockData.carousel.length"
				>
					<div class="slick-carousel" ref="carousel">
						<div
							v-for="(item, index) in blockData.carousel"
							:key="index"
							class="carousel-slide"
						>
							<img
								:src="getImage(item.carouselImage)"
								:alt="item.carouselText || 'carousel image'"
								class="carousel-image"
							/>
							<!-- <Link
								v-if="item.carouselLink"
								:link="item.carouselLink"
							>
								<button class="carousel-link-btn">
									{{ item.carouselLinkText ?? "Learn More" }}
								</button>
							</Link> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { SplitText } from "gsap/dist/SplitText";
import Link from "../../components/new/Link.vue";
import $ from "jquery";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import imageUrlBuilder from "@sanity/image-url";
import sanity from "../../client";

const imageBuilder = imageUrlBuilder(sanity);

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

export default {
	name: "TalkSeries",
	props: {
		blockData: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		Link
	},
	data() {
		return {
			slickOptions: {
				infinite: true,
				speed: 500,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: false,
				dots: true,
				fade: true,
				prevArrow:
					'<button type="button" class="slick-prev">Previous</button>',
				nextArrow:
					'<button type="button" class="slick-next">Next</button>'
			}
		};
	},
	methods: {
		getImage(image) {
			return image ? imageBuilder.image(image).url() : "";
		},
		initializeCarousel() {
			$(this.$refs.carousel).slick(this.slickOptions);
		},
		timeline() {
			if (!this.$refs?.title) return false;
			this.$refs.title.style.opacity = "1";
			let tl = gsap.timeline(),
				mySplitText = new SplitText(this.$refs.title, {
					type: "lines"
				}),
				lines = mySplitText.lines;

			gsap.set(this.$refs.title, { perspective: 400 });

			tl.from(
				lines,
				{
					y: 40,
					opacity: 0,
					duration: 0.6,
					stagger: 0.1,
					rotationX: 80,
					transformOrigin: "20% 0 0"
				},
				"+=0"
			);
		},
		timelinePara() {
			if (this.$refs?.title) this.$refs.title.style.opacity = "1";
		}
	},
	created() {
		setTimeout(function () {
			ScrollTrigger.refresh();
		}, 50);
	},
	mounted() {
		this.initializeCarousel();

		ScrollTrigger.create({
			trigger: ".paragraph",
			toggleActions: "play none none none",
			onEnter: () => this.timeline(),

			onLeaveBack: (self) => self.disable()
		});

		ScrollTrigger.create({
			trigger: ".paragraph",
			toggleActions: "play none none none",
			onEnter: () => this.timelinePara(),

			onLeaveBack: (self) => self.disable()
		});
	},
	beforeUnmount() {
		if (this.$refs.carousel) {
			$(this.$refs.carousel).slick("unslick");
		}
	}
};
</script>

<style scoped>
.what-we-do-section-container {
	padding-top: 8vw;
	padding-bottom: 15vw;
	width: 100vw;
	background: #f7f5f2;

	margin-top: 0;
}

.no-bg {
	background: transparent;
}

.git {
	position: relative;
	margin-top: 2.5vw;
	text-align: left;
	-webkit-transition: 1s;
	transition: 1s;
	font-size: 1.3vw;
	float: left;
	opacity: 1;
	font-weight: 700;
	cursor: pointer;
	font-family: DM sans;
	color: #544a3f;
	-webkit-text-decoration-color: #d4c09e;
	text-decoration-color: #d4c09e;
	text-decoration: underline;
}
.title {
	position: relative;
	text-align: left;
	/*margin-left: 11vw;*/
	line-height: 5.8vw;
	font-size: 6.25vw;
	width: 35vw;
	/*margin-top: 2vw;*/
	opacity: 0;
}
.title-small {
	position: relative;
	text-align: left !important;
	width: 88vw;
	display: inline-block;
	opacity: 0;
	margin-bottom: 0.67em;
}

@media (min-aspect-ratio: 200/200) {
	.title-small {
		position: relative;
		text-align: left;
		font-size: 3vw;
		width: 35vw;
		display: inline-block;
		opacity: 0;
		margin-bottom: 0.67em;
	}
}

.sm-header.flex-image-left .picture-container {
	width: 45vw;
}

@media (max-aspect-ratio: 200/200) {
	.sm-header.flex-image-left .picture-container {
		width: 88vw;
	}
}

.flex {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

@media (max-aspect-ratio: 200/200) {
	.flex {
		display: contents;
	}
}

.flex-edge-standard {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.flex-edge-standard {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.flex-edge-standard .title {
	width: 43vw;
}

@media (max-aspect-ratio: 200/200) {
	.flex-edge-standard {
		display: contents;
	}

	.flex-edge-standard a {
		font-size: 4vw;
		left: 22px;
	}
}

.flex-edge-standard .title {
	width: 43vw;
}

.flex-edge-standard div:first-child {
	margin-right: 10vw;
}

.flex-image-left {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-evenly;
	align-items: center;
}

@media (max-aspect-ratio: 200/200) {
	.flex-image-left {
		display: contents;
	}
	.flex-edge-standard .title {
		width: 67vw;
	}
}

.flex-edge-left {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;
}

.flex-edge-left div:first-child {
	margin-left: 10vw;
}

.flex-edge-left .picture-container {
	height: 40vw;
}

.flex-edge-left .picture-containr img {
	height: 40vw;
	object-fit: cover;
}

.flex-edge-standard .picture-container {
	height: 40vw;
}

.flex-edge-standard .picture-container img {
	height: 40vw;
	object-fit: cover;
}

.picture-container {
	position: relative;
	height: 37vw;
	width: 37vw;
	/*overflow: hidden;*/
}
.what-we-do-pic {
	width: 100%;
	position: relative;
}
.what-we-do-section-container img {
	height: 100%;
	object-fit: cover;
}
.paragraph {
	position: relative;
	margin-top: -2.5vw;
	text-align: left;
	/*margin-left: 11vw;*/
	transition: 1s;
	font-size: 1.3vw;
	width: 35vw;
	opacity: 1;
	font-family: DM sans;
}

.what-we-do-btn {
	position: relative;
	left: 0;
	margin-top: 50px;
	width: 18vw;
	height: 4.5vw;
	display: block;

	background: none;
	border: 0.2vw solid #d4c09e;
	cursor: pointer;
}

.what-we-do-btn h3 {
	font-size: 0.9vw;
	color: #544a3f;
	padding: 0.45vw;
}

.carousel-container {
	width: 100%;
	margin-top: 0px;
}

.slick-carousel {
	position: relative;
	margin: 0 3.8vw;
}

.carousel-slide {
	position: relative;
	text-align: center;
}

.carousel-image {
	width: 100%;
	min-height: 30vw;
	max-height: 30vw;
	object-fit: cover;
}
.slick-dots li.slick-active button:before {
	opacity: 0.75 !important;
	color: #544a3f !important;
}
.slick-dots li button:before {
	border: 1px solid #544a3f !important;
	border-radius: 50% !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

@media (max-aspect-ratio: 200/200) {
	.flex-edge-standard .picture-container {
		height: 100vw;
	}

	.flex-edge-standard .picture-container img {
		height: 100vw;
	}

	.title {
		margin-left: 6vw;
		line-height: 10vw;

		font-size: 11.25vw;
		width: 90vw;
		margin-top: 2vw;
	}
	.paragraph {
		text-align: left;
		font-size: 3.8vw;
		width: 88vw;
		margin-left: 6vw;
	}
	.picture-container {
		margin-left: 0;
		left: 0;
		margin-top: 10vw;
		width: 100vw;
		height: 80vw;
	}
	.carousel-container img {
		min-height: 78vw;
		max-height: 78vw;
	}
	.what-we-do-btn {
		margin-top: 3vw;
		margin-left: 6vw;
		width: 50vw;
		height: 15vw;

		border: 0.5vw solid #d4c09e;
	}

	.what-we-do-btn h3 {
		font-size: 2.5vw;
		color: #544a3f;
		padding: 0.45vw;
	}
	.what-we-do-section-container {
		padding-bottom: 15vw;
	}
	.carousel-container {
		width: 100%;
		margin-top: 5vw;
	}
}
</style>

<style>
.slick-dots {
	bottom: -30px;
}
.slick-dots li button,
.slick-dots li {
	width: 15px;
	height: 15px;
	padding: 0;
}
.slick-dots li {
	border: 3px solid #e1d6c2 !important;
	border-radius: 50% !important;
	overflow: hidden;
	width: 15px;
	height: 15px;
}

.slick-dots li button:before {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0;
	width: 50%;
	height: 50%;
	overflow: hidden;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0 auto;
}
.slick-dots li.slick-active button:before {
	opacity: 1;
	background-color: #544a3f;
}
</style>
